section.letterContainer {
    display: flex; flex-direction: column;
    height: 200px; width: 400px;
    padding: 1rem;
    justify-content: space-between;
}
section.letterContainer.hidden {
    pointer-events: none;
    opacity: .4;
}
section.letterContainer > div {
    font-size: .6rem;
    text-align: end;
    color: black;
    font-weight: bold;
}
section.letterContainer > div i { 
    color: black; font-weight: normal;
}
section.letterContainer strong {
    background: linear-gradient(140deg, #4a4a4c, var(--secondary), #080a0b);
    margin-bottom: 2px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    width: fit-content;
}
section.letterContainer form {
    display: flex; flex-direction: column;
}
section.letterContainer form div {
    font-size: .6rem;
    padding: 2px 10px;
    display: flex;
    align-items: center; justify-content: space-between;
}
section.letterContainer form div:last-child {
    align-items: flex-start;
    border: 1px solid black;
}
section.letterContainer form div input, section.letterContainer form div textarea {
    border: none; outline: none;
    caret-color: var(--primary);
    color: var(--primary);
    background: none;
    width: 100%;
    font-size: .5rem;
    border-bottom: 1px solid var(--secondary);
    padding: 2px 8px;
    font-family: monospace;
}
section.letterContainer form div input::spelling-error, section.letterContainer form div textarea::spelling-error,
section.letterContainer form div input::grammar-error, section.letterContainer form div textarea::grammar-error {
    text-decoration: underline rgb(255, 56, 56);
}
section.letterContainer form div input::placeholder, section.letterContainer form div textarea::placeholder {
    color: var(--secondary);
    font-style: normal;
    font-size: .6rem;
    font-family: "Inter", sans-serif;
}
section.letterContainer form div textarea {
    resize: none;
    border: 1px solid var(--secondary);
    height: 3.6rem;
}
section.letterContainer form button {
    background: linear-gradient(140deg, #3a3f49, var(--secondary),  #191c21);
    border: none; outline: none;
    align-self: flex-end;
    margin: 2px 10px;
    font-size: .6rem;
    color: white;
    padding: 2px 18px;
    border-radius: 4px;
    cursor: pointer;
}

section.letterContainer form button:hover {
    background: linear-gradient(140deg, var(--primary),  var(--primary-dark), #002f45);
}