.cupContainer {
    z-index: var(--html-z);
    position: fixed;
    width: 100vw; height: 100vh;
    top: 0; left: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, .8), rgba(0, 0, 0, .0) 65%);
    transition: all .4s ease-in-out;
}
.cupContainer.hidden {
    transform: translate(-100%, 0%);
}
.cupContainer.hidden .cupWrapper {
    transform: translate(20%, 0%);
}
.cupWrapper {
    display: flex;
    align-items: center; justify-content: center;
    width: 60%; height: 100%;
    transform: none;
    transition: all .4s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-delay: .2s;
}
.cupWrapper > div {
    display: flex; flex-direction: column;
    gap: 1rem;
    color: white;
}
.cupWrapper .name {
    letter-spacing: .45rem;
    font-size: 6rem;
    line-height: 5rem;
}
.cupWrapper ul {
    display: flex;
    gap: 2rem;
    padding: 0 1rem;
}
.cupWrapper ul li {
    padding: .6rem;
    width: 150px;
    display: flex; gap: .4rem;
    align-items: center; justify-content: center;
    background: linear-gradient(140deg, #3a3f49, var(--secondary),  #191c21);
    border-radius: 12px;
    cursor: pointer;
}
.cupWrapper ul a {
    text-decoration: none;
}
.cupWrapper ul li img { 
    height: 1.2rem; 
    filter: brightness(1.8);
}

.cupWrapper ul li:hover {
    background: linear-gradient(140deg, var(--primary),  var(--primary-dark), #002f45);
    border: none;
}

.scrollContainer {
    position: absolute;
    top: 74%; left: 50%;
    width: 1.8rem; height: 3.4rem;
    transform: translate(-50%, -50%);
    border: 2px solid white;
    border-radius: 1000px;
    opacity: .6;
}
#scroll-icon {
    position: absolute;
    background-color: white;
    width: 1rem; height: 1.4rem;
    top: 50%; left: 50%;
    animation: scrollAnim 1.5s infinite;
    border-radius: 1000px;
}
@keyframes scrollAnim {
    0%, 100% {
        transform: translate(-50%, -100%);
    } 50% {
        transform: translate(-50%, 0%);
    }
}
.cupWrapper p {
    font-size: 1.4rem;
}
.cupWrapper h2 {
    font-size: 1.4rem;
    font-weight: normal;
    padding: .5rem 1rem;
}
.cupWrapper h2 strong {
    padding: 0 .3rem;
}