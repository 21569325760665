nav {
    display: flex;
    align-items: center; justify-content: center;
    padding-top: .2rem;
    position: fixed;
    top: 0; left: 0;
    z-index: var(--nav-z);
    width: 100vw;
}
nav ul {
    display: flex; gap: .6rem;
    justify-content: center; align-items: center;
    list-style: none;
    padding: .2rem 1.6rem;
    border-radius: 1000px;
    background-color: var(--secondary);
    overflow: hidden;
}
.navWrapper {
    padding: .2rem;
    border-radius: 1000px;
    position: relative;
    background: linear-gradient(-30deg, white, var(--primary) 30%, var(--primary-dark));
    transform: scale(.6);
    opacity: .4;
    transition: all .2s ease;
}
.navWrapper:hover, 
nav.active .navWrapper {
    opacity: 1;
    transform: scale(.8);
}
nav.deactive .navWrapper {
    transform: scale(.6) translateY(-100px);
    opacity: 0;
    pointer-events: none;
}
nav ul li {
    border-radius: 1000px;
    display: flex;
    align-items: center; justify-content: center;
    width: 3.4rem; height: 3.4rem;
    transition: all .1s ease;
    cursor: pointer;
    background: linear-gradient(140deg, var(--primary-dark), var(--primary) 70%, white);

    /* ---------------------------------------- */
    mask-image: var(--mask-image);
    -webkit-mask-image: var(--mask-image);
    

    -webkit-mask-size: 2rem;
    mask-size: 2rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
}
nav ul li.active {
    transform: none !important;
    mask-image: var(--mask-active-image);
    -webkit-mask-image: var(--mask-active-image);
    background: var(--primary)
}
nav ul li:hover { 
    transform: scale(1.3); 
}