.loadContainer {
    z-index: var(--loader-z);
    position: fixed;
    top: 0; left: 0;
    width: 100vw; height: 100vh;
    display: flex; 
    background-image: url('./../../../public/backgrounds/loadingBgrd.png');
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 1.2s ease-out;
}
.loadWrapper {
    gap: 2rem;
    width: 100vw; height: 100vh;
    display: flex; flex-direction: column;
    align-items: center; justify-content: center;
    backdrop-filter: blur(100px);
}
.loaderIconContainer {
    display: flex; gap: 1rem;
}

.loaderIconContainer img {
    height: 3rem;
    animation: bounce 2s infinite;
}

.loaderIconContainer img:nth-child(1) { animation-delay: 0s; }
.loaderIconContainer img:nth-child(2) { animation-delay: .1s; }
.loaderIconContainer img:nth-child(3) { animation-delay: .2s; }
.loaderIconContainer img:nth-child(4) { animation-delay: .3s; }
.loaderIconContainer img:nth-child(6) { animation-delay: .5s; }
.loaderIconContainer img:nth-child(7) { animation-delay: .7s; }
.loaderIconContainer img:nth-child(8) { animation-delay: .8s; }
.loaderIconContainer img:nth-child(9) { animation-delay: .9s; padding: .3rem; }

.loaderIconContainer span { width: 1rem; }

.loadingBar {
    width: 60vw;
    border-radius: 100px;
    background-color: black;
    border: 1px solid black;
}

.loadingBarInner {
    width: 0%;
    height: .5rem;
    transition: all .1s ease;
    background: linear-gradient(-90deg, var(--primary), var(--primary-dark));
    border-radius: 1000px;
}

.loadingText { color: var(--primary); }

.loadContainer.hidden {
    opacity: 0;
    transform: translateY(100%);
}

.loadContainer.hidden img { animation: none !important; }

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    } 20%, 80% {
        transform: translateY(5px) scale(1.021, .9);
    } 50% {
        transform: translateY(-20px) scale(.9, 1.01);
    }
}