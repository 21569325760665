@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
    user-select: none;
    color: white;
}

:root {
    --primary: #9bdfff;
    --primary-dark: #016391;
    --secondary: #282c34;
    /* --secondary: #006b17; */
    --secondary-transparent: #282c3460;

    --loader-z: 1000;
    --nav-z: 999;
    --html-z: 998;
}

html, body {
    font-size: 16px;
    overflow: hidden;
}

#root {
    width: 100vw;
    height: 100vh;
}
ul { list-style: none; }
strong {
    background: linear-gradient(160deg, white, var(--primary) 10%, var(--primary-dark));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}