section#androidScreen {
    display: flex; flex-direction: column;
    height: 430px; width: 200px;
    justify-content: space-between;
    pointer-events: none;
    border-radius: 4px;
}
section#androidScreen ul.item-ul {
    width: 100%; height: 100%;
    border-radius: 30px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: relative;
    overflow: hidden;
}
section#androidScreen ul.item-ul li.proj-item {
    width: 100%; height: 100%;
    position: absolute;
    overflow: hidden;
}
section#androidScreen li.invisible {
    opacity: 0; top: 10%;
    transition: all .1s ease-out;
}
section#androidScreen li.visible {
    opacity: 1; top: 0%;
    transition: all .2s ease-in;
}
section#androidScreen ul.proj-img-ul { 
    display: flex; 
    height: 100%; width: 100%;
    position: relative; 
} 
section#androidScreen img.proj-img {
    object-fit: contain;
    width: 100%; height: 100%;
    padding: 0;
    position: absolute;
    /* top: 0%; left: 100%; */
    top: 0; left: 0;
    transition: all .5s ease;
    opacity: 0;
}
section#androidScreen img.proj-img.active {
    opacity: 1;
}