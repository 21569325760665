.computerContainer {
    display: flex;
    align-items: center; justify-content: end;
    z-index: var(--html-z);
    position: absolute;
    right: 0; top: 0;
    width: 100vw; height: 100vh;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    transition: all .4s ease-in-out;
    opacity: 1;
}
.computerContainer.hidden {
    right: -100%;
    opacity: 0;
}
.computerWrapper {
    display: flex; flex-direction: column;
    align-items: center; justify-content: center;
    gap: 1.4rem;
    width: 50%;
}
.computerWrapper strong {
    font-size: 3rem;
    letter-spacing: .1rem;
}
.computerWrapper > ul {
    display: flex; flex-direction: column;
    align-items: center;
    width: 80%; 
    position: relative;
}
.computerWrapper > ul li {
    width: 100%; 
    border-bottom: 1px solid white;
    position: relative;
    overflow: hidden;
    transition: all .4s ease;
}

.computerWrapper > ul li.last-item { border: none; }

.computerWrapper .data {
    width: 100%; 
    display: flex; gap: .5rem;
    align-items: center; justify-content: space-between;
    padding: .8rem 2.2rem;
    padding-right: 0px;
    transition: all .2s ease;
}
.computerWrapper .data .name {
    display: flex; flex-direction: column;
    font-size: 1.6rem;
    flex: 1;
    position: relative;
    transition: all .2s ease;
}
.computerWrapper .data .name span {
    position: absolute;
    font-size: 1rem;
    font-style: italic;
    top: 150%;
    transition: all .2s ease;
    padding-right: .5rem;
}
.computerWrapper .data .links {
    display: flex; flex-direction: column;
    width: 8rem;
}
.computerWrapper .data .links a {
    transform: translateY(30px);
    opacity: 0;
    pointer-events: none;
    text-decoration: none;
    transition: all .2s ease;
    display: flex; gap: .5rem;
    align-items: center;
}
.computerWrapper .data .links img {
    height: 1rem;
    filter: brightness(1.8);
}
.computerWrapper .tech-imgs {
    position: absolute;
    top: 50%; right: 2.2rem;
    height: 2.2rem;
    transform: translate(0%, -50%);
    display: flex; 
    gap: .5rem;
    transition: all .2s ease;
    transition-delay: .02s;
}
.computerWrapper .tech-imgs img { 
    height: 100%; 
}
.computerWrapper > ul li:hover .tech-imgs {
    height: 6rem;
}
.computerWrapper > ul li:hover .data {
    backdrop-filter: blur(40px);
}
.computerWrapper > ul li:hover a {
    opacity: 1 !important;
    pointer-events: all !important;
    transform: none !important;
}
.computerWrapper > ul li:hover .name {
    color: transparent;
    padding: 1.4rem 0px;
}
.computerWrapper > ul li:hover .name span {
    top: 50%;
    transform: translate(0%, -50%);
}
.computerWrapper > ul li a:nth-child(1) { transition-delay: .1s !important; }
.computerWrapper > ul li a:nth-child(2) { transition-delay: .15s !important; }

.computerWrapper > ul.android li.web {
    position: absolute; opacity: 0;
    pointer-events: none;
}
.computerWrapper > ul.web li.android {
    position: absolute; opacity: 0;
    pointer-events: none;
}
